<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col cols="6">
          <v-row no-gutters>
            <v-col
              cols="12"
            >
              <base-app-text-field
                v-model="config.id"
                label="Report ID"
                disabled
              />
            </v-col>
            <v-col
              cols="12"
              @mouseenter="onEnter('name')"
              @mouseleave="onLeave()"
            >
              <base-app-text-field
                v-model="config.name"
                label="Report name"
              />
            </v-col>
            <v-col
              cols="12"
              @mouseenter="onEnter('domoId')"
              @mouseleave="onLeave()"
            >
              <base-app-text-field
                v-model="config.providerId"
                label="Domo Embed ID"
              />
            </v-col>
            <v-col
              cols="12"
              @mouseenter="onEnter('published')"
              @mouseleave="onLeave()"
            >
              <v-checkbox
                v-model="config.published"
                label="Published"
              />
            </v-col>
            <v-col
              cols="12"
              @mouseenter="onEnter('visibleForRoles')"
              @mouseleave="onLeave()"
            >
              <v-select
                v-model="config.visibleForRoles"
                :items="agencyRoles"
                item-value="text"
                max-width="500"
                label="Visible for Roles"
                multiple
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    :color="item.color"
                    outlined
                    large
                    close
                    @click:close="config.visibleForRoles = config.visibleForRoles.filter(e => e !== item.text)"
                  >
                    <span class="text-h5">{{ item.text }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
            <v-col
              cols="12"
              @mouseenter="onEnter('visibleForSolution')"
              @mouseleave="onLeave()"
            >
              <v-select
                v-model="config.visibleForSolution"
                :items="solutions"
                max-width="500"
                label="Visible for Solution"
              >
                <template v-slot:selection="{ item }">
                  <v-chip
                    large
                    color="ccloud"
                    close
                    @click:close="config.visibleForSolution = null"
                  >
                    <span class="text-h5">{{ item }}</span>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-col>
        <v-col class="col">
          {{ infotext }}
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <base-app-dialog-action-button
        outlined
        @click="previewConf"
      >
        Preview
      </base-app-dialog-action-button>
      <base-app-dialog-action-button
        :disabled="saveDisabled"
        @click="saveConf"
      >
        Save
      </base-app-dialog-action-button>
      <base-app-dialog-action-button
        color="ccred"
        @click="deleteConf"
      >
        Delete
      </base-app-dialog-action-button>
    </v-card-actions>
  </v-card>
</template>

<script>
  import { mapMutations } from 'vuex'
  import { portalRoleEnums, domoReportAllowedForSolutionsEnums, domoHelperTexts } from '@/constants'
  import isEqual from 'lodash/isEqual'

  export default {
    name: 'ReportConfig',
    props: {
      config: {
        type: Object,
        required: true,
      },
    },
    data () {
      return {
        domoHelperTexts,
        oldVal: JSON.parse(JSON.stringify(this.config)),
        solutions: domoReportAllowedForSolutionsEnums,
        agencyRoles: portalRoleEnums,
        infotext: null,
      }
    },
    computed: {
      saveDisabled () {
        const val = !!(isEqual(this.config, this.oldVal) ||
          !this.config.name.length ||
          !this.config.providerId.length
        )
        return val
      },
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
      }),
      saveConf () {
        this.$emit('saveReport', this.config)
      },
      deleteConf () {
        this.$emit('deleteReport', this.config.id)
      },
      previewConf () {
        this.$emit('previewReport', this.config.id)
      },
      onEnter (id) {
        this.infotext = this.domoHelperTexts[id]
      },
      onLeave () {
        this.infotext = null
      },
    },
  }
</script>
<style scoped>
.cursor-pointer tbody tr:hover {
  cursor: pointer;
}
</style>
