<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="1200"
    height="1000"
  >
    <v-card>
      <base-app-dialog-title
        title="REPORT PREVIEW"
        @close="onCancel"
      />
      <v-card-text>
        <v-row justify="space-around">
          <v-col cols="10">
            <iframe
              width="1000px"
              height="900px"
              style="border:none;"
              :srcdoc="html"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'PreviewDialog',
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
      html: {
        type: String,
        required: true,
      },
    },
    data () {
      return {
      }
    },
    methods: {
      onCancel () {
        this.$emit('close')
      },
    },
  }
</script>
