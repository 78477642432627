<template>
  <v-container
    id="reports"
    fluid
    tag="section"
  >
    <app-confirmation-dialog
      :dialog="deleteConfirmationDialog"
      title="Delete report"
      text="Are you sure you want to delete report from CC? Report is removed from CC Web portal"
      @confirm="deleteConfirmed"
    />
    <new-report-dialog
      :dialog="newReportDialog"
      @close="newReportDialog = false"
      @created="reportCreated"
    />
    <preview-dialog
      v-if="previewHtml"
      :dialog="previewDialog"
      :html="previewHtml"
      @close="closePreview"
    />
    <v-row>
      <v-col>
        <div class="text-h4 font-weight-light">
          <p />
          <p>
            Here you can control what DOMO dashboards are displayed in CareConnect web portal for agency employees.
          </p>
          <p>
            First step is to head over to Domo website.
            <a href="https://careconnectmobile.domo.com">https://careconnectmobile.domo.com</a>
            There you must create a dashboard and publish it.
            After that you can control it in admin tool.
          </p>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <app-dialog-action-button
          @click="newReportDialog = true"
          @close="newReportDialog = false"
        >
          Create a new report
        </app-dialog-action-button>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        v-for="config in configs"
        :key="config.id"
        cols="12"
      >
        <report-config
          :config="config"
          @saveReport="saveReport"
          @deleteReport="deleteReport"
          @previewReport="loadReportPreview"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { eventBus, busEvents } from '@/EventBus'
  import { mapMutations } from 'vuex'
  import ReportConfig from './component/report/ReportConfig'
  import AppConfirmationDialog from '@/views/dashboard/components/core/AppConfirmationDialog.vue'
  import AppDialogActionButton from '../../components/base/AppDialogActionButton.vue'
  import NewReportDialog from '@/views/dashboard/component/report/NewReportDialog.vue'
  import PreviewDialog from '@/views/dashboard/component/report/PreviewDialog.vue'

  export default {
    name: 'ReportsView',
    components: {
      'report-config': ReportConfig,
      'app-confirmation-dialog': AppConfirmationDialog,
      AppDialogActionButton,
      NewReportDialog,
      PreviewDialog,
    },
    data () {
      return {
        previewHtml: null,
        previewDialog: false,
        newReportDialog: false,
        deleteConfirmationDialog: false,
        configs: [],
      }
    },
    mounted () {
      this.loadData()
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
      }),
      loadData () {
        this.setLoading(true)
        this.$careConnect.listReports()
          .then(resp => {
            this.configs = resp
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load reports'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      saveReport (data) {
        const { id, ...rest } = data
        this.setLoading(true)
        this.$careConnect.updateReport(id, rest)
          .then(resp => {
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to save report'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      deleteReport (data) {
        this.reportId = data
        this.deleteConfirmationDialog = true
      },
      deleteConfirmed (confirmed) {
        if (confirmed) {
          this.$careConnect.deleteReport(this.reportId)
            .then(() => {
              this.configs = this.configs.filter(e => e.id !== this.reportId)
            })
            .catch(err => {
              const text = err.json ? err.json.error : 'Failed to delete report'
              eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
            })
            .finally(() => {
              this.reportId = null
              this.setLoading(false)
            })
        }
        this.deleteConfirmationDialog = false
      },
      reportCreated (data) {
        this.configs.push(data)
      },
      loadReportPreview (id) {
        this.setLoading(true)
        this.$careConnect.previewReport(id)
          .then((resp) => {
            this.previewHtml = resp.html
            this.previewDialog = true
          })
          .catch(() => {
            const text = 'Failed to load report preview, check Domo Embed ID'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.reportId = null
            this.setLoading(false)
          })
      },
      closePreview () {
        this.previewDialog = false
        this.previewHtml = null
      },
    },
  }
</script>
