<template>
  <v-dialog
    v-model="dialog"
    persistent
    scrollable
    width="800"
  >
    <v-card>
      <base-app-dialog-title
        title="NEW REPORT"
        @close="onCancel"
      />
      <v-card-text>
        <v-form
          ref="lkmdf"
          v-model="valid"
        >
          <v-row>
            <v-col cols="6">
              <v-row no-gutters>
                <v-col
                  cols="12"
                  @mouseenter="onEnter('name')"
                  @mouseleave="onLeave()"
                >
                  <base-app-text-field
                    v-model="name"
                    label="Report name"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col
                  cols="12"
                  @mouseenter="onEnter('domoId')"
                  @mouseleave="onLeave()"
                >
                  <base-app-text-field
                    v-model="providerId"
                    label="Domo Embed ID"
                    required
                    :rules="requiredRule"
                  />
                </v-col>
                <v-col
                  cols="12"
                  @mouseenter="onEnter('published')"
                  @mouseleave="onLeave()"
                >
                  <v-checkbox
                    v-model="published"
                    label="Published"
                  />
                </v-col>
                <v-col
                  cols="12"
                  @mouseenter="onEnter('visibleForRoles')"
                  @mouseleave="onLeave()"
                >
                  <v-select
                    v-model="visibleForRoles"
                    :items="agencyRoles"
                    item-value="text"
                    max-width="500"
                    label="Visible for Roles"
                    multiple
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        :color="item.color"
                        outlined
                        large
                        close
                        @click:close="visibleForRoles = visibleForRoles.filter(e => e !== item.text)"
                      >
                        <span class="text-h5">{{ item.text }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
                <v-col
                  cols="12"
                  @mouseenter="onEnter('visibleForSolution')"
                  @mouseleave="onLeave()"
                >
                  <v-select
                    v-model="visibleForSolution"
                    :items="solutions"
                    max-width="500"
                    label="Visible for Solution"
                  >
                    <template v-slot:selection="{ item }">
                      <v-chip
                        large
                        color="ccloud"
                        close
                        @click:close="visibleForSolution = null"
                      >
                        <span class="text-h5">{{ item }}</span>
                      </v-chip>
                    </template>
                  </v-select>
                </v-col>
              </v-row>
            </v-col>
            <v-col class="col">
              {{ infotext }}
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <base-app-dialog-action-button
          :disabled="!valid"
          @click="createReport"
        >
          Create
        </base-app-dialog-action-button>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { portalRoleEnums, domoReportAllowedForSolutionsEnums, domoHelperTexts } from '@/constants'
  import { eventBus, busEvents } from '@/EventBus'
  import { mapMutations } from 'vuex'

  export default {
    name: 'NewReportDialog',
    props: {
      dialog: {
        type: Boolean,
        required: true,
      },
    },
    data () {
      return {
        domoHelperTexts,
        name: '',
        providerId: '',
        published: false,
        visibleForRoles: [],
        visibleForSolution: null,
        infotext: null,
        valid: false,
        // validators
        requiredRule: [
          v => !!v || false,
        ],
        solutions: domoReportAllowedForSolutionsEnums,
        agencyRoles: portalRoleEnums,
      }
    },
    methods: {
      ...mapMutations({
        setLoading: 'app/SET_LOADING',
      }),
      onCancel () {
        this.$emit('close')
      },
      createReport () {
        const { name, providerId, published, visibleForRoles, visibleForSolution } = this

        const req = {
          name,
          providerId,
          published,
          visibleForRoles,
          visibleForSolution,
        }
        this.setLoading(true)
        this.$careConnect.createReport(req)
          .then(resp => {
            this.$emit('created', resp)
            const text = 'Report saved'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'success', text })
            this.onCancel()
          })
          .catch(err => {
            const text = err.json ? err.json.error : 'Failed to load reports'
            eventBus.$emit(busEvents.APP_NOTIFICATION, { type: 'error', text })
          })
          .finally(() => {
            this.setLoading(false)
          })
      },
      onEnter (id) {
        this.infotext = this.domoHelperTexts[id]
      },
      onLeave () {
        this.infotext = null
      },
    },
  }
</script>
